






















































































































import Vue from "vue";
import {Route} from "vue-router";
import {RouteNext} from "@/types";
import {Component} from 'vue-property-decorator';
import {vxm} from "@/store";
import {taxonomyFields} from "@/types/consts";
import {TaxonomyBrief} from "@/service";

@Component
export default class TaxonomyPage extends Vue {

  private FIELDS = taxonomyFields;

  private taxonomyPage = vxm.taxonomyStore.taxonomyPage;

  private gotoPage(item: TaxonomyBrief) {
    this.$router.push({
      name: "TaxonomyDetail",
      params: {list: this.$route.params.list, node: item.node.toString()}
    });
  }


}
